.alert {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  position: relative;
  z-index: 1000;
  justify-content: left;
  align-items: center;
}

.alert-form {
  padding: 5px;
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  position: relative;
  z-index: 1000;
  justify-content: left;
  align-items: center;
}

.alert-form {
  padding: 5px;
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  position: relative;
  z-index: 1000;
  justify-content: left;
  align-items: center;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.warning {
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
}

.info {
  background-color: #d1ecf1;
  color: #0c5460;
  border: 1px solid #bee5eb;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.icon-container {
  margin-right: 8px;
  margin-top: 4px;
}

.custom-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1201;
}

.custom-dialog-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1202;
  padding-top: 0;
  padding-bottom: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.button-container {
  text-align: right;
  margin-bottom: 10px;
}
